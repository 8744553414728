<template>
    <div class="hold-transition">
      <div class="row">
        <div class="form-group col-md-4">
          <label for="gerente_area">Nombre Gerente Area</label>
          <v-select
            :class="[
              $v.gerente_area.$invalid ? 'is-invalid' : 'is-valid',
              $store.getters.getDarkMode ? 'dark-vselect' : '',
            ]"
            v-model="gerente_area"
            placeholder="Gerente Area"
            label="nombres"
            :options="listasForms.gerente"
            class="form-control form-control-sm p-0"
            :disabled="$parent.form.estado == 2"
            @input="gerenteArea()"
          ></v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <table
            class="
              table table-bordered table-striped table-hover table-sm
              text-xs
            "
          >
            <thead class="bg-gray text-center">
              <tr>
                <!-- <th class="text-nowrap">
                  Acciones
                  <button
                    type="button"
                    class="btn btn-sm bg-primary text-white"
                    @click="addContable()"
                    :disabled="
                      estado_contable == 1 ||
                      ($parent.form.id && !gerente_area) ||
                      $parent.form.estado == 2
                    "
                    style="cursor: pointer"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </th> -->
                <th class="text-center" style="width: 10%">Área</th>
                <th class="text-center">OPEX/CAPEX</th>
                <th class="text-center">Dueño AFE</th>
                <th class="text-center">CECO/AFE/G&A</th>
                <th class="text-center">
                  Centro Logístico al que Pertenece el CeCo/AFE
                </th>
                <th class="text-center">Nombre del Centro</th>
                <th class="text-center">
                  % de la Operación Para cargar este CeCo/AFE
                </th>
              </tr>
            </thead>
            <tbody id="tbody">
              <tr v-for="(contable, index) in csContables" :key="contable.id">
                <!-- <td class="text-center">
                  <div class="btn-group" v-if="estado_contable != 2">
                    <button
                      type="button"
                      class="btn bg-navy"
                      :disabled="estado_contable != -1"
                      @click="editContable(index)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      class="btn bg-danger"
                      :disabled="estado_contable != -1"
                      @click="destroyContable(index, contable)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                  <div
                    class="btn-group"
                    v-if="estado_contable == 2 && id_actualizar_contable == index"
                  >
                    <button
                      type="button"
                      class="btn bg-success"
                      v-show="!$v.csContable.$invalid"
                      @click="saveUpdateContable(index)"
                    >
                      <i class="fas fa-save"></i>
                    </button>
                    <button class="btn bg-danger" @click="cancelContable()">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                  <div
                    class="btn-group"
                    v-if="
                      estado_contable == 2 && id_actualizar_contable !== index
                    "
                  >
                    <button
                      type="button"
                      class="btn bg-navy"
                      disabled
                      @click="editContable(index)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      class="btn bg-danger"
                      disabled
                      @click="destroyContable(index)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td> -->
                <td class="text-center">
                  <div
                    v-if="id_actualizar_contable == index && estado_contable == 2"
                  >
                    <select
                      id="area"
                      class="form-control form-control-sm col-md-12"
                      v-model="csContable.area_obj"
                      :class="
                        $v.csContable.area_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="area in listasForms.areas"
                        :key="area.numeracion"
                        :value="area"
                      >
                        {{ area.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        contable.area_obj !== undefined &&
                        contable.responsable_obj !== null
                      "
                    >
                      {{ contable.area_obj.descripcion }}
                    </div>
                    <div v-else>
                      {{ contable.nArea }}
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div
                    v-if="id_actualizar_contable == index && estado_contable == 2"
                  >
                    <select
                      id="tipo_cargo"
                      class="form-control form-control-sm col-md-12"
                      v-model="csContable.tipo_cargo_obj"
                      :class="
                        $v.csContable.tipo_cargo_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @change="getTipoCargo()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="opex in listasForms.opex_capex"
                        :key="opex.numeracion"
                        :value="opex"
                      >
                        {{ opex.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        contable.tipo_cargo_obj !== undefined &&
                        contable.tipo_cargo_obj !== null
                      "
                    >
                      {{ contable.tipo_cargo_obj.descripcion }}
                    </div>
                    <div v-else>
                      {{ contable.opexCapex }}
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div
                    v-if="id_actualizar_contable == index && estado_contable == 2"
                  >
                    <v-select
                      :class="[
                        $v.csContable.responsable_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="csContable.responsable_obj"
                      placeholder="Responsable"
                      label="funcionario"
                      class="form-control form-control-sm p-0"
                      :options="listasForms.responsables"
                      :filterable="true"
                    ></v-select>
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        contable.responsable_obj !== undefined &&
                        contable.responsable_obj !== null
                      "
                    >
                      {{ contable.responsable_obj.funcionario }}
                    </div>
                    <div v-else>
                      {{ contable.responsable ? `${contable.responsable.nombres} ${contable.responsable.apellidos}` : '' }}
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div
                    class="row"
                    v-if="id_actualizar_contable == index && estado_contable == 2"
                  >
                    <div class="col-md-6 pr-1" v-if="csContable.tipo_cargo_obj">
                      <select
                        placeholder="Ceco AFE"
                        label="nombres"
                        class="form-control form-control-sm p-0"
                        v-model="csContable.tipo_presupuesto"
                        :class="
                          $v.csContable.tipo_presupuesto.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @change="getCecosAfes()"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          value="1"
                          :disabled="csContable.tipo_cargo_obj.numeracion != 1"
                        >
                          AFE
                        </option>
                        <option
                          value="2"
                          :disabled="csContable.tipo_cargo_obj.numeracion != 2"
                        >
                          CECO
                        </option>
                      </select>
                    </div>
                    <div class="col-md-6 pl-0">
                      <v-select
                        :class="[
                          $v.csContable.cecosAfes_obj.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        class="form-control form-control-sm p-0"
                        style="font-size: 10px"
                        v-model="csContable.cecosAfes_obj"
                        v-show="csContable.tipo_presupuesto"
                        placeholder="CeCo / AFE"
                        label="codigo_contable"
                        :options="listasForms.cecos_afes"
                      ></v-select>
                    </div>
                  </div>
                  <div v-else>
                    <div class="row">
                      <div class="col-md-4 pr-1">
                        <span
                          class="badge"
                          :class="
                            contable.tipo_presupuesto == 1 ? 'bg-info' : 'bg-navy'
                          "
                        >
                          {{ contable.tipo_presupuesto == 1 ? "AFE" : "CECO" }}
                        </span>
                      </div>
                      <div
                        class="col-md-8 pl-0"
                        v-if="
                          contable.cecosAfes_obj !== undefined &&
                          contable.cecosAfes_obj !== null
                        "
                      >
                        <input
                          :value="contable.cecosAfes_obj.codigo_contable"
                          type="text"
                          class="form-control form-control-sm"
                          style="font-size: 9px"
                          disabled
                        />
                      </div>
                      <div class="col-md-8 pl-0" v-else>
                        <input
                          :value="contable.ceco_afe ? contable.ceco_afe.codigo_contable : ''"
                          type="text"
                          class="form-control form-control-sm"
                          style="font-size: 9px"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div
                    v-if="id_actualizar_contable == index && estado_contable == 2"
                  >
                    <select
                      id="centro_logistico"
                      class="form-control form-control-sm col-md-12"
                      v-model="csContable.centroCosto_obj"
                      :class="
                        $v.csContable.centroCosto_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="centroC in listasForms.centros_costo"
                        :key="centroC.numeracion"
                        :value="centroC"
                      >
                        {{ centroC.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        contable.centroCosto_obj !== undefined &&
                        contable.centroCosto_obj !== null
                      "
                    >
                      {{ contable.centroCosto_obj.descripcion }}
                    </div>
                    <div v-else>
                      {{ contable.centroLogistico }}
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div
                    v-if="id_actualizar_contable == index && estado_contable == 2"
                  >
                    <input
                      v-if="csContable.centroCosto_obj"
                      type="text"
                      class="form-control form-control-sm"
                      id="nombre_centro"
                      v-model="csContable.centroCosto_obj.cod_alterno"
                      disabled
                    />
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        contable.centroCosto_obj !== undefined &&
                        contable.centroCosto_obj !== null
                      "
                    >
                      {{ contable.centroCosto_obj.cod_alterno }}
                    </div>
                    <div v-else>
                      {{ contable.nombreCentro }}
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div
                    v-if="id_actualizar_contable == index && estado_contable == 2"
                  >
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="porcentaje"
                      v-model="csContable.porcentaje"
                      :class="
                        $v.csContable.porcentaje.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div v-else>
                    {{ contable.porcentaje }}
                  </div>
                </td>
              </tr>
              <tr v-if="estado_contable == 1">
                <!-- <td>
                  <div class="btn-group float-right">
                    <button
                      class="btn bg-success"
                      v-show="!$v.csContable.$invalid"
                      @click="saveContable()"
                    >
                      <i class="fas fa-save"></i>
                    </button>
                    <button class="btn bg-danger" @click="cancelContable()">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td> -->
                <td>
                  <select
                    id="area"
                    class="form-control form-control-sm col-md-12"
                    v-model="csContable.area_obj"
                    :class="
                      $v.csContable.area_obj.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="area in listasForms.areas"
                      :key="area.numeracion"
                      :value="area"
                    >
                      {{ area.descripcion }}
                    </option>
                  </select>
                </td>
                <td>
                  <select
                    id="tipo_cargo"
                    class="form-control form-control-sm col-md-12"
                    v-model="csContable.tipo_cargo_obj"
                    :class="
                      $v.csContable.tipo_cargo_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @change="getTipoCargo()"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="opex in listasForms.opex_capex"
                      :key="opex.numeracion"
                      :value="opex"
                    >
                      {{ opex.descripcion }}
                    </option>
                  </select>
                </td>
                <td>
                  <v-select
                    :class="[
                      $v.csContable.responsable_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="csContable.responsable_obj"
                    placeholder="Responsable"
                    label="funcionario"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.responsables"
                    :filterable="true"
                  ></v-select>
                </td>
                <td>
                  <div class="row">
                    <div class="col-md-6 pr-1" v-if="csContable.tipo_cargo_obj">
                      <select
                        placeholder="Ceco AFE"
                        label="nombres"
                        class="form-control form-control-sm p-0"
                        style="font-size: 10px"
                        v-model="csContable.tipo_presupuesto"
                        :class="
                          $v.csContable.tipo_presupuesto.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @change="getCecosAfes()"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          value="1"
                          :disabled="csContable.tipo_cargo_obj.numeracion != 1"
                        >
                          AFE
                        </option>
                        <option
                          value="2"
                          :disabled="csContable.tipo_cargo_obj.numeracion != 2"
                        >
                          CECO
                        </option>
                      </select>
                    </div>
                    <div class="col-md-6 pl-0">
                      <v-select
                        :class="[
                          $v.csContable.cecosAfes_obj.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        class="form-control form-control-sm p-0"
                        style="font-size: 10px"
                        v-model="csContable.cecosAfes_obj"
                        v-show="csContable.tipo_presupuesto"
                        placeholder="CeCo / AFE"
                        label="codigo_contable"
                        :options="listasForms.cecos_afes"
                      ></v-select>
                    </div>
                  </div>
                </td>
                <td>
                  <select
                    id="centro_logistico"
                    class="form-control form-control-sm col-md-12"
                    v-model="csContable.centroCosto_obj"
                    :class="
                      $v.csContable.centroCosto_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="centroP in listasForms.centros_costo"
                      :key="centroP.numeracion"
                      :value="centroP"
                    >
                      {{ centroP.descripcion }}
                    </option>
                  </select>
                </td>
                <td>
                  <div v-if="csContable.centroCosto_obj">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="nombre_centro"
                      v-model="csContable.centroCosto_obj.cod_alterno"
                      disabled
                    />
                  </div>
                  <div v-else>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="nombre_centro"
                      disabled
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="porcentaje"
                    v-model="csContable.porcentaje"
                    :class="
                      $v.csContable.porcentaje.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { required } from "vuelidate/lib/validators";
  import vSelect from "vue-select";
  import axios from "axios";
  
  export default {
    name: "CsAsignacionesPresupuesto",
    components: {
      vSelect,
    },
    data() {
      return {
        gerente_area: null,
        csContables: [],
        csContable: {
          id: null,
          cs_solicitud_nacional_id: null,
          area: null,
          area_obj: null,
          tipo_cargo: null,
          tipo_cargo_obj: null,
          cantidad_vh_equi: null,
          tipo_presupuesto: null,
          ceco_afe_id: null,
          responsable_obj: null,
          responsable_id: null,
          porcentaje: null,
          centro_logistico: null,
          centroCosto_obj: null,
          cecosAfes_obj: null,
        },
        listasForms: {
          tipos_equipos: [],
          areas: [],
          gerente: [],
          centros_costo: [],
          opex_capex: [],
          responsables: [],
          cecos_afes: [],
        },
        totalPresupuesto: 0,
        estado_contable: -1,
        id_actualizar_contable: -1,
      };
    },
    validations: {
      csContable: {
        area_obj: {
          required,
        },
        tipo_cargo_obj: {
          required,
        },
        tipo_presupuesto: {
          required,
        },
        responsable_obj: {
          required,
        },
        porcentaje: {
          required,
        },
        centroCosto_obj: {
          required,
        },
        cecosAfes_obj: {
          required,
        },
      },
      gerente_area: {
        required,
      },
    },
  
    methods: {
      async getCsContable() {
        await axios
          .get("/api/funcionariosFrontera/solicitudesNacionalesPresupuesto", {
            params: { solicitudNacional_id: this.$parent.form.id },
          })
          .then(async (response) => {
            this.csContables = response.data;
            if (
              this.$route.params.accion == "Editar" &&
              this.$route.params.data_edit.gerente_area
            ) {
              this.gerente_area = this.$route.params.data_edit.gerente_area;
            }
            await this.getCecosAfes(response.data.tipo_presupuesto);
            await this.getGerente();
            await this.calcularContable();
            await this.llenarContable();
          });
      },
  
      async getTipoEquipos() {
        await axios.get("/api/lista/129").then((response) => {
          this.listasForms.tipos_equipos = response.data;
        });
      },
  
      getAreas() {
        axios.get("/api/lista/99").then((response) => {
          this.listasForms.areas = response.data;
        });
      },
  
      getCentroLogistico() {
        this;
        axios.get("/api/lista/133").then((response) => {
          this.listasForms.centros_costo = response.data;
        });
      },
  
      getOpexCapex() {
        axios.get("/api/lista/132").then((response) => {
          this.listasForms.opex_capex = response.data;
        });
      },
  
      getResponsables() {
        axios.get("/api/admin/funcionarios/list").then((response) => {
          this.listasForms.responsables = response.data;
        });
      },
  
      getTipoCargo(){
        this.csContable.tipo_presupuesto = null;
        this.csContable.cecosAfes_obj = null;
      },
  
      async getGerente() {
        axios
          .get("/api/admin/funcionarios/lista", {
            params: {
              gerente_area: 1,
              area: this.$parent.user.funcionario[0].area,
            },
          })
          .then((response) => {
            this.listasForms.gerente = response.data;
          });
      },
  
      async getCecosAfes(tipoP = null) {
        this.listasForms.cecos_afes = [];
        if (tipoP == null) {
          if (this.csContable.tipo_presupuesto == "") {
            this.csContable.tipo_cargo_obj = null;
          }
          tipoP = this.csContable.tipo_presupuesto;
        }
        await axios
          .get("/api/funcionariosFrontera/wsObjetosActivos/lista", {
            params: {
              tipo_presupuesto: tipoP,
            },
          })
          .then((response) => {
            this.listasForms.cecos_afes = response.data;
          });
      },
  
      async llenarContable() {
        await this.csContables.forEach(async (element) => {
          element.area_obj = await this.listasForms.areas.filter((item) => {
            if (item.numeracion == element.area) {
              return item;
            }
          })[0];
  
          element.tipo_cargo_obj = await this.listasForms.opex_capex.filter(
            (item) => {
              if (item.numeracion == element.tipo_cargo) {
                return item;
              }
            }
          )[0];
  
          let responsable = {
            id: element.responsable.id,
            funcionario:
              element.responsable.nombres +
              " " +
              element.responsable.apellidos,
            user_id: element.responsable.user_id,
          };
          element.responsable_obj = responsable;
  
          element.centroCosto_obj = await this.listasForms.centros_costo.filter(
            (item) => {
              if (item.numeracion == element.centro_logistico) {
                return item;
              }
            }
          )[0];
  
          element.cecosAfes_obj = await this.listasForms.cecos_afes.filter(
            (item) => {
              if (item.id == element.ceco_afe.id) {
                return item;
              }
            }
          )[0];
        });
      },
  
      async calcularContable() {
        let totalPresupuesto = 0;
        this.csContables.forEach((element) => {
          totalPresupuesto += parseFloat(element.porcentaje);
        });
        this.totalPresupuesto = totalPresupuesto;
        return totalPresupuesto;
      },
  
      async gerenteArea() {
        this.$parent.form.gerente_area_id = this.gerente_area.id;
      },
  
      async addContable() {
        await this.limpiarContable();
        this.csContable.cs_solicitud_nacional_id = this.$parent.form.id;
        this.estado_contable = 1;
      },
  
      async saveContable() {
        let me = this;
        await me.calcularContable();
        if (
          me.totalPresupuesto + parseFloat(me.csContable.porcentaje) <= 100 &&
          me.csContable.porcentaje > 0
        ) {
          me.csContable.area = me.csContable.area_obj.numeracion;
          me.csContable.tipo_cargo = me.csContable.tipo_cargo_obj.numeracion;
          if (me.csContable.responsable_obj.user_id !== null) {
            me.csContable.responsable_id = me.csContable.responsable_obj.user_id;
            me.csContable.centro_logistico =
              me.csContable.centroCosto_obj.numeracion;
            me.csContable.ceco_afe_id = me.csContable.cecosAfes_obj.id;
  
            me.csContables.push(me.csContable);
            me.estado_contable = -1;
          } else {
            me.$swal({
              icon: "error",
              title:
                "El funcionario seleccionado no esta relacionado con algun usuario",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
  
          await me.calcularContable();
        } else {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error,el porcentaje no puede ser mayor a 100",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      },
  
      async saveContableTotal() {
        this.cargando = true;
        axios({
          method: "POST",
          url: "/api/funcionariosFrontera/solicitudesNacionalesPresupuesto",
          data: {
            cs_solicitud_nacional_id: this.$parent.form.id,
            contables: this.csContables,
            cs_solicitud_type: "App\\CsSolicitudNacional",
            cs_solicitud_presupuesto_type: "App\\CsSolicitudNacionalPresupuesto",
            gerente_area: this.gerente_area,
          },
        })
          .then(async (response) => {
            this.cargando = false;
            await this.getCsContable();
            await this.crearAutorizaciones();
            this.guardar++;
          })
          .catch((e) => {
            this.message += e.message;
          });
      },
  
      async editContable(index) {
        await this.getCecosAfes(this.csContables[0].tipo_presupuesto);
        this.id_actualizar_contable = await index;
        this.estado_contable = 2;
        this.csContable = await { ...this.csContables[index] };
      },
  
      async saveUpdateContable(index) {
        let me = this;
        me.csContable.area = me.csContable.area_obj.numeracion;
        me.csContable.tipo_cargo = me.csContable.tipo_cargo_obj.numeracion;
        if (me.csContable.responsable_obj.user_id !== null) {
          me.csContable.responsable_id = me.csContable.responsable_obj.user_id;
          me.csContable.centro_logistico =
            me.csContable.centroCosto_obj.numeracion;
          me.csContable.ceco_afe_id = me.csContable.cecosAfes_obj.id;
  
          this.csContables[index] = this.csContable;
          this.estado_contable = -1;
          this.id_actualizar_contable = -1;
          await me.calcularContable();
        } else {
          me.$swal({
            icon: "error",
            title:
              "El funcionario seleccionado no esta relacionado con algun usuario",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      },
  
      async limpiarContable() {
        this.csContable = {
          id: null,
          cs_solicitud_nacional_id: null,
          area: null,
          area_obj: null,
          tipo_cargo: null,
          tipo_cargo_obj: null,
          cantidad_vh_equi: null,
          tipo_presupuesto: null,
          ceco_afe_id: null,
          responsable_obj: null,
          responsable_id: null,
          porcentaje: null,
          centro_logistico: null,
          centroCosto_obj: null,
          cecosAfes_obj: null,
        };
      },
  
      async cancelContable() {
        this.estado_contable = -1;
      },
  
      async destroyContable(index, contable = null) {
        this.cargando = true;
        await this.calcularContable();
        if (contable.id != null) {
          await this.$swal({
            title:
              "Esta seguro de eliminar el funcionario: " +
              contable.responsable_obj.funcionario +
              "?",
            text:
              "Los cambios no se pueden revertir y recuerde que debe asignar un nuevo funcionario ya que el % de la operacion queda en " +
              (parseFloat(this.totalPresupuesto) -
                parseFloat(contable.porcentaje)) +
              " %",
            icon: "error",
            showCancelButton: true,
            confirmButtonText: "Si, Eliminar!",
          }).then(async(result) => {
            if (result.value) {
              await axios
                .delete(
                  "/api/funcionariosFrontera/solicitudesNacionalesPresupuesto/" +
                    contable.id
                )
                .then(() => {
                  this.$swal({
                    icon: "success",
                    title: "Se eliminó el sitio exitosamente...",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                  this.getIndex();
                  this.csContables.splice(index, 1);
                  this.cargando = false;
                  this.addContable();
                });
            }
          });
        } else {
          this.csContables.splice(index, 1);
        }
      },
    },
  
    async mounted() {
      await this.getTipoEquipos();
      await this.getAreas();
      await this.getOpexCapex();
      await this.getResponsables();
      await this.getCentroLogistico();
    },
  };
  </script>
  