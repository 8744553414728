var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"gerente_area"}},[_vm._v("Nombre Gerente Area")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
          _vm.$v.gerente_area.$invalid ? 'is-invalid' : 'is-valid',
          _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
        ],attrs:{"placeholder":"Gerente Area","label":"nombres","options":_vm.listasForms.gerente,"disabled":_vm.$parent.form.estado == 2},on:{"input":function($event){return _vm.gerenteArea()}},model:{value:(_vm.gerente_area),callback:function ($$v) {_vm.gerente_area=$$v},expression:"gerente_area"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm text-xs"},[_vm._m(0),_c('tbody',{attrs:{"id":"tbody"}},[_vm._l((_vm.csContables),function(contable,index){return _c('tr',{key:contable.id},[_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_contable == index && _vm.estado_contable == 2)?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.csContable.area_obj),expression:"csContable.area_obj"}],staticClass:"form-control form-control-sm col-md-12",class:_vm.$v.csContable.area_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"id":"area"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.csContable, "area_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.areas),function(area){return _c('option',{key:area.numeracion,domProps:{"value":area}},[_vm._v(" "+_vm._s(area.descripcion)+" ")])})],2)]):_c('div',[(
                    contable.area_obj !== undefined &&
                    contable.responsable_obj !== null
                  )?_c('div',[_vm._v(" "+_vm._s(contable.area_obj.descripcion)+" ")]):_c('div',[_vm._v(" "+_vm._s(contable.nArea)+" ")])])]),_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_contable == index && _vm.estado_contable == 2)?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.csContable.tipo_cargo_obj),expression:"csContable.tipo_cargo_obj"}],staticClass:"form-control form-control-sm col-md-12",class:_vm.$v.csContable.tipo_cargo_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"id":"tipo_cargo"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.csContable, "tipo_cargo_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getTipoCargo()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.opex_capex),function(opex){return _c('option',{key:opex.numeracion,domProps:{"value":opex}},[_vm._v(" "+_vm._s(opex.descripcion)+" ")])})],2)]):_c('div',[(
                    contable.tipo_cargo_obj !== undefined &&
                    contable.tipo_cargo_obj !== null
                  )?_c('div',[_vm._v(" "+_vm._s(contable.tipo_cargo_obj.descripcion)+" ")]):_c('div',[_vm._v(" "+_vm._s(contable.opexCapex)+" ")])])]),_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_contable == index && _vm.estado_contable == 2)?_c('div',[_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                    _vm.$v.csContable.responsable_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid',
                    _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                  ],attrs:{"placeholder":"Responsable","label":"funcionario","options":_vm.listasForms.responsables,"filterable":true},model:{value:(_vm.csContable.responsable_obj),callback:function ($$v) {_vm.$set(_vm.csContable, "responsable_obj", $$v)},expression:"csContable.responsable_obj"}})],1):_c('div',[(
                    contable.responsable_obj !== undefined &&
                    contable.responsable_obj !== null
                  )?_c('div',[_vm._v(" "+_vm._s(contable.responsable_obj.funcionario)+" ")]):_c('div',[_vm._v(" "+_vm._s(contable.responsable ? `${contable.responsable.nombres} ${contable.responsable.apellidos}` : '')+" ")])])]),_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_contable == index && _vm.estado_contable == 2)?_c('div',{staticClass:"row"},[(_vm.csContable.tipo_cargo_obj)?_c('div',{staticClass:"col-md-6 pr-1"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.csContable.tipo_presupuesto),expression:"csContable.tipo_presupuesto"}],staticClass:"form-control form-control-sm p-0",class:_vm.$v.csContable.tipo_presupuesto.$invalid
                        ? 'is-invalid'
                        : 'is-valid',attrs:{"placeholder":"Ceco AFE","label":"nombres"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.csContable, "tipo_presupuesto", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getCecosAfes()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_c('option',{attrs:{"value":"1","disabled":_vm.csContable.tipo_cargo_obj.numeracion != 1}},[_vm._v(" AFE ")]),_c('option',{attrs:{"value":"2","disabled":_vm.csContable.tipo_cargo_obj.numeracion != 2}},[_vm._v(" CECO ")])])]):_vm._e(),_c('div',{staticClass:"col-md-6 pl-0"},[_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.csContable.tipo_presupuesto),expression:"csContable.tipo_presupuesto"}],staticClass:"form-control form-control-sm p-0",class:[
                      _vm.$v.csContable.cecosAfes_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                    ],staticStyle:{"font-size":"10px"},attrs:{"placeholder":"CeCo / AFE","label":"codigo_contable","options":_vm.listasForms.cecos_afes},model:{value:(_vm.csContable.cecosAfes_obj),callback:function ($$v) {_vm.$set(_vm.csContable, "cecosAfes_obj", $$v)},expression:"csContable.cecosAfes_obj"}})],1)]):_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 pr-1"},[_c('span',{staticClass:"badge",class:contable.tipo_presupuesto == 1 ? 'bg-info' : 'bg-navy'},[_vm._v(" "+_vm._s(contable.tipo_presupuesto == 1 ? "AFE" : "CECO")+" ")])]),(
                      contable.cecosAfes_obj !== undefined &&
                      contable.cecosAfes_obj !== null
                    )?_c('div',{staticClass:"col-md-8 pl-0"},[_c('input',{staticClass:"form-control form-control-sm",staticStyle:{"font-size":"9px"},attrs:{"type":"text","disabled":""},domProps:{"value":contable.cecosAfes_obj.codigo_contable}})]):_c('div',{staticClass:"col-md-8 pl-0"},[_c('input',{staticClass:"form-control form-control-sm",staticStyle:{"font-size":"9px"},attrs:{"type":"text","disabled":""},domProps:{"value":contable.ceco_afe ? contable.ceco_afe.codigo_contable : ''}})])])])]),_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_contable == index && _vm.estado_contable == 2)?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.csContable.centroCosto_obj),expression:"csContable.centroCosto_obj"}],staticClass:"form-control form-control-sm col-md-12",class:_vm.$v.csContable.centroCosto_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"id":"centro_logistico"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.csContable, "centroCosto_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.centros_costo),function(centroC){return _c('option',{key:centroC.numeracion,domProps:{"value":centroC}},[_vm._v(" "+_vm._s(centroC.descripcion)+" ")])})],2)]):_c('div',[(
                    contable.centroCosto_obj !== undefined &&
                    contable.centroCosto_obj !== null
                  )?_c('div',[_vm._v(" "+_vm._s(contable.centroCosto_obj.descripcion)+" ")]):_c('div',[_vm._v(" "+_vm._s(contable.centroLogistico)+" ")])])]),_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_contable == index && _vm.estado_contable == 2)?_c('div',[(_vm.csContable.centroCosto_obj)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.csContable.centroCosto_obj.cod_alterno),expression:"csContable.centroCosto_obj.cod_alterno"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","id":"nombre_centro","disabled":""},domProps:{"value":(_vm.csContable.centroCosto_obj.cod_alterno)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.csContable.centroCosto_obj, "cod_alterno", $event.target.value)}}}):_vm._e()]):_c('div',[(
                    contable.centroCosto_obj !== undefined &&
                    contable.centroCosto_obj !== null
                  )?_c('div',[_vm._v(" "+_vm._s(contable.centroCosto_obj.cod_alterno)+" ")]):_c('div',[_vm._v(" "+_vm._s(contable.nombreCentro)+" ")])])]),_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_contable == index && _vm.estado_contable == 2)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.csContable.porcentaje),expression:"csContable.porcentaje"}],staticClass:"form-control form-control-sm",class:_vm.$v.csContable.porcentaje.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"number","id":"porcentaje"},domProps:{"value":(_vm.csContable.porcentaje)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.csContable, "porcentaje", $event.target.value)}}})]):_c('div',[_vm._v(" "+_vm._s(contable.porcentaje)+" ")])])])}),(_vm.estado_contable == 1)?_c('tr',[_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.csContable.area_obj),expression:"csContable.area_obj"}],staticClass:"form-control form-control-sm col-md-12",class:_vm.$v.csContable.area_obj.$invalid ? 'is-invalid' : 'is-valid',attrs:{"id":"area"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.csContable, "area_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.areas),function(area){return _c('option',{key:area.numeracion,domProps:{"value":area}},[_vm._v(" "+_vm._s(area.descripcion)+" ")])})],2)]),_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.csContable.tipo_cargo_obj),expression:"csContable.tipo_cargo_obj"}],staticClass:"form-control form-control-sm col-md-12",class:_vm.$v.csContable.tipo_cargo_obj.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"id":"tipo_cargo"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.csContable, "tipo_cargo_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getTipoCargo()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.opex_capex),function(opex){return _c('option',{key:opex.numeracion,domProps:{"value":opex}},[_vm._v(" "+_vm._s(opex.descripcion)+" ")])})],2)]),_c('td',[_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                  _vm.$v.csContable.responsable_obj.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Responsable","label":"funcionario","options":_vm.listasForms.responsables,"filterable":true},model:{value:(_vm.csContable.responsable_obj),callback:function ($$v) {_vm.$set(_vm.csContable, "responsable_obj", $$v)},expression:"csContable.responsable_obj"}})],1),_c('td',[_c('div',{staticClass:"row"},[(_vm.csContable.tipo_cargo_obj)?_c('div',{staticClass:"col-md-6 pr-1"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.csContable.tipo_presupuesto),expression:"csContable.tipo_presupuesto"}],staticClass:"form-control form-control-sm p-0",class:_vm.$v.csContable.tipo_presupuesto.$invalid
                        ? 'is-invalid'
                        : 'is-valid',staticStyle:{"font-size":"10px"},attrs:{"placeholder":"Ceco AFE","label":"nombres"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.csContable, "tipo_presupuesto", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getCecosAfes()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_c('option',{attrs:{"value":"1","disabled":_vm.csContable.tipo_cargo_obj.numeracion != 1}},[_vm._v(" AFE ")]),_c('option',{attrs:{"value":"2","disabled":_vm.csContable.tipo_cargo_obj.numeracion != 2}},[_vm._v(" CECO ")])])]):_vm._e(),_c('div',{staticClass:"col-md-6 pl-0"},[_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.csContable.tipo_presupuesto),expression:"csContable.tipo_presupuesto"}],staticClass:"form-control form-control-sm p-0",class:[
                      _vm.$v.csContable.cecosAfes_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                    ],staticStyle:{"font-size":"10px"},attrs:{"placeholder":"CeCo / AFE","label":"codigo_contable","options":_vm.listasForms.cecos_afes},model:{value:(_vm.csContable.cecosAfes_obj),callback:function ($$v) {_vm.$set(_vm.csContable, "cecosAfes_obj", $$v)},expression:"csContable.cecosAfes_obj"}})],1)])]),_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.csContable.centroCosto_obj),expression:"csContable.centroCosto_obj"}],staticClass:"form-control form-control-sm col-md-12",class:_vm.$v.csContable.centroCosto_obj.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"id":"centro_logistico"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.csContable, "centroCosto_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.centros_costo),function(centroP){return _c('option',{key:centroP.numeracion,domProps:{"value":centroP}},[_vm._v(" "+_vm._s(centroP.descripcion)+" ")])})],2)]),_c('td',[(_vm.csContable.centroCosto_obj)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.csContable.centroCosto_obj.cod_alterno),expression:"csContable.centroCosto_obj.cod_alterno"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","id":"nombre_centro","disabled":""},domProps:{"value":(_vm.csContable.centroCosto_obj.cod_alterno)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.csContable.centroCosto_obj, "cod_alterno", $event.target.value)}}})]):_c('div',[_c('input',{staticClass:"form-control form-control-sm",attrs:{"type":"text","id":"nombre_centro","disabled":""}})])]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.csContable.porcentaje),expression:"csContable.porcentaje"}],staticClass:"form-control form-control-sm",class:_vm.$v.csContable.porcentaje.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"type":"number","id":"porcentaje"},domProps:{"value":(_vm.csContable.porcentaje)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.csContable, "porcentaje", $event.target.value)}}})])]):_vm._e()],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-gray text-center"},[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"width":"10%"}},[_vm._v("Área")]),_c('th',{staticClass:"text-center"},[_vm._v("OPEX/CAPEX")]),_c('th',{staticClass:"text-center"},[_vm._v("Dueño AFE")]),_c('th',{staticClass:"text-center"},[_vm._v("CECO/AFE/G&A")]),_c('th',{staticClass:"text-center"},[_vm._v(" Centro Logístico al que Pertenece el CeCo/AFE ")]),_c('th',{staticClass:"text-center"},[_vm._v("Nombre del Centro")]),_c('th',{staticClass:"text-center"},[_vm._v(" % de la Operación Para cargar este CeCo/AFE ")])])])
}]

export { render, staticRenderFns }